import React, { useEffect, useState } from "react";
import {
  CrossIcon,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import Table from "../../atom/table";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { useNavigate } from "react-router-dom";
import {
  getAllNpaNumbers,
  getAllNpas,
  getAllRateCenters,
  getAllStates,
  purchaseDIDS,
} from "../../services/api";
import Notification from "../../atom/Notification";
import { addChildrensToMapData } from "../../services/utilities";
import Lottiefy from "../../atom/lottie/lottie";

function PurchaseDidModal() {
  const navigate = useNavigate();
  const [didTable, setDIdTable] = useState<boolean>(false);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);

  const handleCallback = (data: any, key: string) => {
    console.log("callback", data, "key", key);
    if (key === "state") {
      getRateCenter(data?.state_code);
    } else if (key === "rate_center") {
      getNpa(data?.rate_center);
    }
  };

  const [didData, setDidData] = useState<any>([
    {
      title: "Select State",
      name: "state",
      value: "",
      disabled: false,
      type: "multiselectdropdown",
      displayProps: {
        label: "state",
        value: "state",
      },
      Callback: handleCallback,
      childrens: [],
      required: false,
      errormessage: "Select a State",
    },
    {
      name: "rate_center",
      title: "Rate Center",
      displayProps: {
        label: "rate_center",
        value: "rate_center",
      },
      type: "multiselectdropdown",
      Callback: handleCallback,
      childrens: [],
      required: true,
      value: "",
      errormessage: "Please select a rate center",
    },
    {
      name: "npa",
      title: "NPA",
      displayProps: {
        label: "npa",
        value: "npa",
      },
      childrens: [],
      type: "multiselectdropdown",
      required: true,
      value: "",
      errormessage: "Please select NPA",
    },
  ]);

  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "did",
      title: "Number",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: true },
    },

    {
      key: "carrier",
      title: "Carrier",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },

    {
      key: "",
      type: "checkbox",
      classname: "float-right ",
      selectAll: true,
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const getRateCenter = (state_code: string) => {
    getAllRateCenters(state_code).then((result: any) => {
      if (!result.success) {
        Notification(
          "Failed",
          `${
            result?.message ? result?.message : "Couldn't find any Rate Centers"
          }`,
          "danger"
        );
      } else {
        setDidData(
          addChildrensToMapData(
            [...didData],
            result?.data?.items,
            "rate_center"
          )
        );
      }
    });
  };

  const getNpa = (rate_center: string) => {
    const state_values = didData.find((item: any) => {
      return item?.name === "state";
    });
    const state_code = state_values?.value.state_code;

    getAllNpas(state_code, rate_center).then((result: any) => {
      if (!result.success) {
        Notification(
          "Failed",
          `${result?.message ? result?.message : "Couldn't find any NPA "}`,
          "danger"
        );
      } else {
        setDidData(
          addChildrensToMapData([...didData], result?.data?.items, "npa")
        );
      }
    });
  };

  const getStates = () => {
    getAllStates().then((result: any) => {
      if (!result.success) {
        Notification(
          "Failed",
          `${result?.message ? result?.message : "Couldn't find any states"}`,
          "danger"
        );
      } else {
        setDidData(
          addChildrensToMapData([...didData], result?.data?.items, "state")
        );
      }
    });
  };

  const onHandleDidSearch = (result: any) => {
    setDIdTable(true);
    setTableLoader(true);
    const payload = {
      state_code: result?.state?.state_code,
      rate_center_abbr: result?.rate_center?.rate_center_abbr,
      npa: result?.npa?.npa,
      hide_unassigned: true,
    };
    getAllNpaNumbers(
      payload.state_code,
      payload.rate_center_abbr,
      payload.npa,
      payload.hide_unassigned
    ).then((result: any) => {
      const data = result?.data?.items;
      if (!result.success || data?.length === 0) {
        setTableLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
        setTableLoader(false);
      }
      setTableData(data);
    });
  };

  const onHandlePurchase = () => {
    console.log("tabledata", tableData);
    if (tableData[0].length !== 0) {
      setButtonLoader(true);
      const tableDataCopy = [...tableData];
      console.log("tabledata", tableDataCopy);
      const filterchecked = tableDataCopy.filter((node: any) => {
        return node?.checked;
      });

      const filteredNumbers = filterchecked.map((item: any) => ({
        did: item.did,
        carrier: item.carrier,
        unassigned: item.unassigned,
      }));

      const payload: any = {
        npa_id: tableData[0]?.npa_id,
        numbers: filteredNumbers,
      };

      purchaseDIDS(payload).then((result: any) => {
        if (!result.success) {
          Notification(
            "Failed",
            `${
              result?.message
                ? result?.message
                : "Oops! Something went wrong. Please try again later"
            }`,
            "danger"
          );
          setButtonLoader(false);
        } else {
          Notification("Success", "Number added successfully", "success");
          setButtonLoader(false);
          navigate(-1);
        }
      });
    }
  };

  const onCloseModal = () => {
    navigate(-1);
  };

  const selectCheckbox = (action: any, data: any) => {
    console.log("checboxdata", data);
    const clone = [...tableData];
    const ttile = [...tableTitles];
    if (data?.selectAll === true) {
      console.log("rchdddddd");
      const result: any = ttile.find((node: any) => {
        console.log("keyy.node", node.key, "data.key", data.key);
        return node.key === data.key;
      });

      result.checked = !result?.checked || false;
      clone.map((node: any) => {
        node.checked = result?.checked;
      });
    } else {
      const result = clone.find((node: any) => {
        return node.did === data.did;
      });
      result.checked = !result?.checked || false;
    }
    const result = clone.find((node: any) => {
      return node?.checked ? node?.checked : false;
    });

    setTableTitles(ttile);
    setTableData(clone);
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action);
    const actionsMap: any = {
      selectall: selectCheckbox,
      change: selectCheckbox,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <div
      className={`h-[calc(100vh-125px)] basis-[35%] bg-[#fff] relative rounded-md mx-2.5`}
    >
      <div className="flex justify-between p-4 mt-4">
        <b>Purchase DIDs</b>
        <p className="cursor-pointer" onClick={onCloseModal}>
          <CrossIcon />
        </p>
      </div>

      <FadeIn>
        <div className="p-2 pt-4">
          <ComponentMap data={didData} setData={setDidData} />
        </div>
        <div className="text-right w-full pr-2">
          <ThemedButton
            children={"Search"}
            icon="custom"
            theme="primary"
            valiData={didData}
            change={setDidData}
            click={onHandleDidSearch}
          />
        </div>
      </FadeIn>
      {didTable && (
        <>
          {tableLoader ? (
            <div className="h-[300px] w-[450px] flex justify-center items-center mt-10">
              <Lottiefy
                loop={true}
                json={loaderLottie}
                height={60}
                width={60}
              />
            </div>
          ) : (
            <>
              <FadeIn>
                <Table
                  tableTitles={tableTitles}
                  tableData={tableData}
                  setTableData={setTableData}
                  setTableTitles={setTableTitles}
                  button={true}
                  buttonIndex={4}
                  buttonText1={"manage"}
                  width={"w-auto overflow-auto"}
                  scrollHeight={"h-[calc(100vh-580px)]  overflow-y-auto"}
                  permission={"provision.users.put"}
                  actions={true}
                  actionHandler={actionHandler}
                  resultNotFound={resultNotFound}
                />
              </FadeIn>

              <div className="text-right  w-full absolute bottom-0 p-2 pb-4">
                <ThemedButton
                  children={"Cancel"}
                  icon="custom"
                  theme="secondary"
                  click={onCloseModal}
                />
                <ThemedButton
                  children={"Purchase"}
                  icon="custom"
                  theme="primary"
                  disabled={tableData?.length === 0 || resultNotFound}
                  loading={buttonLoader}
                  click={onHandlePurchase}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default PurchaseDidModal;
