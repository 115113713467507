import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";

interface CallState {
  data: any;
}

const initialState: CallState = {
  data: {},
};

const Tenant = createSlice({
  name: "TenantData", // Use a different name for the slice
  initialState,
  reducers: {
    setTenantData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      console.log("state.data", state.data);
    },
  },
});

export const { setTenantData } = Tenant.actions;
export default Tenant.reducer;
