import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import "./textbox.css";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { useTranslation } from "react-i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Constants } from "../config/constants";
import { constQueueInfo } from "../config/constants";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import regEx from "../config/regex";

export default function Textbox(props) {
  const { node, data, change, reset } = props;
  const [passwordToggleState, setPasswordToggleState] = useState(false);
  const [passwordChecker, setPasswordChecker] = useState({
    lowercase: false,
    upercase: false,
    digit: false,
    // special: false,
    length: false,
    final: false,
    updated: false,
  });
  const { t } = useTranslation();
  const setChange = (value) => {
    let updateData = [...data];
    let filter;
    if (reset) {
      reset();
    }
    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    if (filter[0]) {
      filter[0].error = false;
      filter[0].value = value === " " ? "" : value.replace(/\s\s+/g, " ");
      if (filter[0].title === "Workspace URL") {
        filter[0].value = value
          .toLowerCase()
          .replace(/[^a-zA-Z ]/g, "")
          .replaceAll(" ", "");
      }
      if (filter[0].onChangeAction) {
        if (
          filter[0].onChangeAction.action === "trimValue" &&
          filter[0].onChangeAction.state === true
        ) {
          const dummy = updateData.find((n) => {
            return n.name === filter[0].onChangeAction.target;
          });
          if (dummy) {
            dummy.value = value
              .toLowerCase()
              .replace(/[^a-zA-Z ]/g, "")
              .replaceAll(" ", "");
          }
        }
      } else if (filter[0].type === "password") {
        filter[0].showHint = true;
        passChecker(filter[0].value);
      }
    }
    change([...updateData]);
  };
  const passChecker = (password) => {
    var dummy = { ...passwordChecker };
    if (dummy) {
      var reg = regEx;
      dummy.digit = reg.digit.test(password);
      dummy.upercase = reg.upercase.test(password);
      dummy.lowercase = reg.lowercase.test(password);
      dummy.length = password.length >= 8;
      dummy.final =
        dummy.digit && dummy.upercase && dummy.lowercase && dummy.length;
      dummy.updated = true;
      setPasswordChecker(dummy);
    }
  };

  const togglePassword = () => {
    setPasswordToggleState(!passwordToggleState);
  };
  const testnumber = (evt, val) => {
    let theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    if (theEvent.ctrlKey && key === 86) {
      theEvent.returnValue = true;
      return true;
    }
    if (node.type === "number") {
      if (
        !theEvent.shiftKey &&
        ((key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105) ||
          key === 8 ||
          key === 46 ||
          key === 37 ||
          key === 39 ||
          key === 9)
      ) {
        theEvent.returnValue = true;
        console.log("theEvent", theEvent);
      } else {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
        return false;
      }
    } else if (node.type === "float") {
      if (
        !theEvent.shiftKey &&
        ((key >= 48 && key <= 57) ||
          (key >= 96 && key <= 105) ||
          key === 8 ||
          key === 46 ||
          key === 190 ||
          key === 110 ||
          key === 37 ||
          key === 39 ||
          key === 9)
      ) {
        if (
          (val === "" || val.indexOf(".") !== -1) &&
          (key === 190 || key === 110)
        ) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
          return false;
        } else return true;
      } else {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };
  useEffect(() => {
    if (node?.showHint) {
      passChecker(node?.value);
    }
  }, [node?.showHint]);
  return (
    <div
      className={`input relative ${
        node?.type === "password" ? "password" : ""
      }`}
    >
      <TextField
        id={node.name}
        fullWidth={node.fullwidth}
        label={t(node.title) + (node.required ? "*" : "")}
        inputProps={{
          maxLength: node?.maxLength ? node.maxLength : Constants.maxTextLength,
          minLength: node?.minLength ? node.minLength : Constants.minTextLength,
        }}
        variant="outlined"
        type={
          node?.type === "password" && !passwordToggleState
            ? "password"
            : "text"
        }
        multiline={node.type === "description" ? true : false}
        rows={node.type === "description" ? 4 : 1}
        value={node.value}
        disabled={node.disabled || false}
        autoFocus={node?.autoFocus || false}
        style={{
          width: "100%",
          backgroundColor: node?.disabled ? "#aaaaaa1d" : "",
        }}
        onKeyDown={(e) => {
          testnumber(e, node.value);
        }}
        onChange={(e) => setChange(e.target.value)}
        error={node.error}
      />

      {!node.disabled &&
        node?.value &&
        (Constants.maxTextLength === node?.value.length ||
          node?.maxLength === node?.value?.length) && (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement="left"
            title="Maximum length reached"
          >
            <div className="absolute text-[orange] cursor-pointer scale-75 right-[10px] top-2">
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        )}
      {!node.disabled &&
        node?.value &&
        node?.value?.length < node?.minLength && (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement="left"
            title={`Minimum ${node?.minLength}  characters required`}
          >
            <div className="absolute text-[orange] cursor-pointer scale-75 right-[10px] top-2">
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        )}

      {node?.type === "password" && (
        <>
          <span
            className="absolute text-primary right-5 cursor-pointer scale-75 mt-1.5"
            onClick={() => togglePassword()}
          >
            {passwordToggleState ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </span>
          {/* <div
            className={`absolute right-12 pt-1.5 ${
              passwordChecker.final ? "text-[green]" : "text-[red]"
            } cursor-pointer scale-75 -mt-10`}
          >
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="bottom-end"
              title={
                <>
                  <div>
                    <div
                      className={`scale-75 inline-block ${
                        passwordChecker?.lowercase
                          ? "text-[white]"
                          : "text-[red]"
                      }`}
                    >
                      {passwordChecker.lowercase ? (
                        <TaskAltIcon />
                      ) : (
                        <UnpublishedIcon />
                      )}
                    </div>{" "}
                    Minimum 1 Lowercase letter
                  </div>
                  <div>
                    <div
                      className={`scale-75 inline-block ${
                        passwordChecker?.upercase
                          ? "text-[white]"
                          : "text-[red]"
                      }`}
                    >
                      {passwordChecker.upercase ? (
                        <TaskAltIcon />
                      ) : (
                        <UnpublishedIcon />
                      )}
                    </div>{" "}
                    Minimum 1 Uppercase letter
                  </div>
                  <div>
                    <div
                      className={`scale-75 inline-block ${
                        passwordChecker?.digit ? "text-[white]" : "text-[red]"
                      }`}
                    >
                      {passwordChecker.digit ? (
                        <TaskAltIcon />
                      ) : (
                        <UnpublishedIcon />
                      )}
                    </div>{" "}
                    Minimum 1 Digit
                  </div>
                  <div>
                    <div
                      className={`scale-75 inline-block ${
                        passwordChecker?.length ? "text-[white]" : "text-[red]"
                      }`}
                    >
                      {passwordChecker.length ? (
                        <TaskAltIcon />
                      ) : (
                        <UnpublishedIcon />
                      )}
                    </div>{" "}
                    Minimum 8 Character length
                  </div>
                </>
              }
            >
              <div>
                {node?.showHint ? (
                  <>
                    {passwordChecker?.final ? (
                      <TaskAltIcon />
                    ) : (
                      <UnpublishedIcon />
                    )}
                  </>
                ) : null}
              </div>
            </Tooltip>
          </div> */}
        </>
      )}
      {node?.button &&
        (node?.button?.display?.if === undefined ||
          node[node?.button?.display?.if] === node?.button?.display?.value) && (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement="right"
            title={node?.button.helperText ? node.button.helperText : ""}
          >
            <span
              className="absolute text-primary right-5 cursor-pointer mt-4 "
              onClick={node?.button.action}
            >
              {node?.button.icon}
            </span>
          </Tooltip>
        )}
    </div>
  );
}
