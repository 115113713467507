import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";

export default function PaginationButtons(props: any) {
  const { handleChange, pageCount, pageNumber, handlePerPage, perPage } = props;
  //   const classes = useStyles();

  const setChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleChange(value);
  };

  return (
    <div className=" p-2">
      <div className="bg-[#EFEEED] flex  w-full justify-between p-2">
        <div className="p-1 mt-2">
          Showing {(pageNumber - 1) * perPage + 1} to{" "}
          {pageCount < pageNumber * perPage ? pageCount : pageNumber * perPage}{" "}
          of {pageCount} entries
        </div>
        <div className="flex p-1 ">
          <div className=" p-1 mr-2">Records per page</div>
          <select
            className="border border-[#595754] rounded-md px-3 mr-4"
            value={perPage}
            onChange={(e) => handlePerPage(e.target.value)}
            name="rowsPerPage"
            id="rowsPerPage"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>

          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(pageCount / perPage)}
              showFirstButton
              showLastButton
              page={pageNumber}
              onChange={setChange}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
}
