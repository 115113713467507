import { FormControl } from "@mui/material";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Loader from "../containers/loader";

import { useTranslation } from "react-i18next";
import { randomNumber } from "../services/utilities";

export function Multiselectdropdown(props) {
  const { node, data, change, index } = props;
  const { t } = useTranslation();

  const setChange = (event, values) => {
    let updateData = [...data];
    let filter;

    filter = updateData.filter((n) => {
      return n.name === node.name;
    });

    if (filter[0]) {
      filter[0].value = values;
      filter[0].error = false;
    }
    if (node?.Callback) {
      node.Callback(values, node?.name);
    }
    if (index) {
      change([...updateData], index);
    } else {
      change([...updateData]);
    }
  };

  const getSelectedOptions = () => {
    let defaultValue = [];
    // debugger
    if (Array.isArray(node.value)) {
      node.value.map((nn) => {
        if (nn?.uuid) {
          defaultValue.push(node.childrens.find((cc) => cc.uuid === nn.uuid));
          return defaultValue;
        } else {
          return defaultValue.push(node.childrens.find((cc) => cc.uuid === nn));
        }
      });
    } else {
      let dd = node.childrens.find(
        (cc) => cc[node?.displayProps?.value] === node.value
      );
      console.log("dddddddddd", dd);
      defaultValue[0] = dd;
    }
    return node?.multiple ? defaultValue : defaultValue[0];
  };
  return (
    <div className=" relative">
      <FormControl disabled={node?.disabled} className={`w-full`}>
        {node.childrens.length ? (
          <Autocomplete
            multiple={node?.multiple}
            id={node.title}
            title={node.title}
            disabled={node?.disabled}
            options={node.childrens}
            groupBy={
              node?.childrenType === "group" ? (option) => option.group : ""
            }
            onChange={setChange}
            getOptionLabel={(option) =>
              node?.childrenType === "countries"
                ? option?.name || ""
                : option[node?.displayProps?.label] || ""
            }
            value={getSelectedOptions()}
            renderOption={
              node?.childrenType === "countries"
                ? (props, option) => (
                    <Box
                      key={randomNumber()}
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option?.flag_code?.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option?.flag_code?.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      {option.name} +{option.code}
                    </Box>
                  )
                : (props, option) => (
                    <Box {...props} key={randomNumber()} component="li">
                      {option[node?.displayProps?.label]}
                    </Box>
                  )
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={node?.disabled}
                error={node?.disabled ? false : node.error}
                label={t(node.title) + (node.required ? "*" : "")}
                placeholder={t(node.title)}
              />
            )}
          />
        ) : (
          <div className="border cursor-wait border-[rgba(0,0,0,0.2)] p-[6px] flex text-[rgba(0,0,0,0.5)]  rounded-[4px]">
            <Loader size="sm" /> Loading
          </div>
        )}
      </FormControl>
      <div
        className={`${
          node?.disabled
            ? "cursor-not-allowed absolute h-16 top-0 w-[calc(100%-16px)]"
            : ""
        }`}
      ></div>
    </div>
  );
}
