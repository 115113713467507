import { useRef } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import zoomPlugin from "chartjs-plugin-zoom";
import { secondsToHHMMSS } from "../../services/utilities";
import { animate, animations } from "framer-motion";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  zoomPlugin
);

const BarGraph = (props) => {
  const chartRef = useRef();
  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom(); // Reset zoom using chart instance
    }
  };

  const handleClick = (event) => {
    console.log("handleClick");
  };

  console.log(props, "props");
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.label ? props.label : "",
        data: props.data,
        backgroundColor: props.color,
        hoverOffset: 4,
        borderWidth: props.border,
        barPercentage: 5,
        barThickness: 70,
        maxBarThickness: 70,
        minBarLength: 2,
        borderRadius: 5,
      },
    ],
  };
  const data2 = {
    labels: props.labels,
    datasets: [],
  };

  props?.busiestHour?.forEach((props) => {
    data2.datasets.push({
      label: props.label ? props.label : "",
      data: props.data,
      backgroundColor: props.backgroundColor,
      hoverOffset: 4,
      borderWidth: props.border,
      barPercentage: 5,
      barThickness: 35,
      maxBarThickness: 30,
      minBarLength: 1,
      borderRadius: 6,
      // data: props.data,
    });
  });

  const options = {
    // animations: false,
    scales: {
      x: {
        grid: {
          display: false, // This removes the x-axis gridlines
        },
      },
      y: {
        ticks: {
          callback: (value) =>
            `${props.hhmmss ? secondsToHHMMSS(value) : value} `,
        },
        title: {
          text: "sec",
          display: false,
        },
        grid: {
          display: false, // This removes the x-axis gridlines
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: props.multipleBar,
      },
      zoom: {
        pan: {
          enabled: true, // Enables panning
          mode: "x", // Allow panning in both directions
          threshold: 10, // Minimum distance for panning
          speed: 10, // Panning speed
        },
        zoom: {
          drag: {
            enabled: true,
          },
          mode: "x",
        },

        //   wheel: {
        //     enabled: true,
        //   },
        // },
        // mode: "xy",
      },
    },
  };

  return (
    <div className="h-[65%]">
      <Bar
        data={props.multipleBar === true ? data2 : data}
        ref={chartRef}
        onClick={handleClick}
        options={options}
      />

      <button
        onClick={resetZoom}
        className={`mt-6 px-4  "ml-6"  py-2  bg-primary text-white rounded `}
      >
        Reset Zoom
      </button>
    </div>
  );
};
export default BarGraph;
