import React, { useEffect, useState } from "react";
import Modal from "../../atom/modal";
import { useNavigate, useParams } from "react-router-dom";
import FadeIn from "react-fade-in/lib/FadeIn";
import { get_DID_Life_Cycle } from "../../services/api";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import moment from "moment";

function Did_Lifecycle() {
  const navigate = useNavigate();
  const id: any = useParams();
  const [loader, setLoader] = useState<any>(false);
  const [apiData, setApiData] = useState<any>([]);

  const onCloseModal = () => {
    navigate(-1);
  };

  const [lifecycleData, setLifecycleData] = useState<any>([]);

  const getLifeCycle = () => {
    console.log("id", id.id);
    setLoader(false);
    get_DID_Life_Cycle(id.id).then((result: any) => {
      const date = result?.data?.items;
      if (!result?.success) {
        Notification(
          "Failed",
          `${
            result?.message
              ? result?.message
              : "Failed to fetch Lifecycle. Please try again later"
          }`,
          "danger"
        );
        setLoader(false);
      } else {
        setLoader(false);
        console.log("result", result.data);
        date.map((node: any) => {
          node.timestamp = moment(new Date(node.timestamp)).format(
            "MM/DD/YYYY, hh:mm A"
          );
        });
      }
      setApiData(result?.data);
      setLifecycleData(date);
      // node.start_time = moment(new Date(node.origination_ts)).format("LTS");
    });
  };

  useEffect(() => {
    getLifeCycle();
  }, []);

  return (
    <>
      <Modal title={"DID Lifecycle"} closeEvent={onCloseModal}>
        {loader ? (
          <div className="h-fit lg:w-[680px] md:w-[480px] sm:w-[370px] flex justify-center items-center mt-6">
            <Lottiefy loop={true} json={loaderLottie} height={60} width={60} />
          </div>
        ) : (
          <FadeIn>
            <div className=" max-h-[528px] lg:w-[680px] md:w-[480px] sm:w-[370px] overflow-y-auto ">
              {/* number */}
              <div className="bg-[#333] text-[#fff] w-fit rounded-lg p-2 px-4 text-[18px]">
                {apiData?.did}
              </div>
              {/*  */}
              <div className="">
                {lifecycleData.map((data: any, index: number) => {
                  console.log("lifecycleData", lifecycleData);
                  return (
                    <div className="border-b border-[#e6e6e6]">
                      <div className="flex">
                        <div className="bg-[#F78F1E] h-[120px] w-[2px]"></div>

                        <div className="flex  w-full items-center relative">
                          {/* <div className="h-[2px] w-[32px] bg-[#F78F1E]"></div> */}
                          <div className="w-10 h-8 overflow-hidden relative -ml-[2px] -mt-[26px]">
                            <div className="border-l-2 border-b-2 border-[#F78F1E] absolute bottom-0 rounded-[30px] w-20 h-20"></div>
                          </div>
                          {/* date */}
                          <div className="absolute top-[58px] left-[36px] -mt-2">
                            <div className="bg-[#feba1223]  w-fit h-[32px] rounded-[50px] text-[16px] p-2 px-2 flex items-center border-2 border-[#F78F1E]">
                              <p className="rounded-full bg-[#F78F1E] h-3 w-3 mr-2"></p>
                              <p>{data.timestamp}</p>
                            </div>
                            <p className="pt-1 pl-1">{data.event}</p>
                          </div>
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </FadeIn>
        )}
      </Modal>
    </>
  );
}

export default Did_Lifecycle;
