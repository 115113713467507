import React from "react";
import { Routes, Route } from "react-router-dom";
import { useApp } from "../appContext";
import Layout1 from "../layout/layout1/layout1";
import Layout2 from "../layout/layout2/layout2";
import Dashboard from "../containers/dashboard";
import UserProfile from "../containers/modal/userProfile";
import Myxtone from "../containers/myxtone";
import CallsInProgress from "../containers/callRecords/callsInProgress";
import TimeZoneRestriction from "../containers/timeZoneRestriction";
import QueueMonitor from "../containers/liveBoards/queueMonitor/queueMonitor";
import CallReports from "../containers/reports/callReports/callReportsLaunch";
import CallReportsLaunch from "../containers/reports/callReports/callReportsLaunch";
import LegacyCdr from "../containers/callRecords/legacyCdr";
import InitialRouter from "./initialRouter";
import Loader from "../containers/loader";
import Dids from "../containers/dids.tsx/did_Management";
import DidRouter from "../containers/dids.tsx/didRouter";
import Dcid_Management from "../containers/dcid/dcid_Mangement";
const CallRecordsRoute = React.lazy(
  () => import("../containers/callRecords/callRecordsRoute")
);
const CallReportsRoute = React.lazy(
  () => import("../containers/reports/callReports/callReportsRoute")
);
const Login = React.lazy(() => import("../containers/auth/login/login"));
const Provision = React.lazy(() => import("../containers/provision/provision"));

const RouterConfig = () => {
  const appContext = useApp();
  const { layout } = appContext;
  const Layout = layout === "layout1" ? <Layout1 /> : <Layout2 />;

  return (
    <>
      <Routes>
        <Route element={Layout}>
          <Route
            path="app/auth/login"
            element={
              <React.Suspense fallback={<></>}>
                <Login />
              </React.Suspense>
            }
          />
          {/* <Route
            path="app/"
            element={
              <React.Suspense fallback={<></>}>
                Halla
              </React.Suspense>
            }
          /> */}
          <Route
            path="app/dashboard"
            element={
              <React.Suspense fallback={<></>}>
                <Dashboard />
              </React.Suspense>
            }
          />
          <Route
            path="app/myxtone"
            element={
              <React.Suspense fallback={<></>}>
                <Myxtone />
              </React.Suspense>
            }
          />
          <Route
            path="app/provision/provisioning#userprofile"
            element={
              <React.Suspense fallback={<></>}>
                <UserProfile />
              </React.Suspense>
            }
          />
          <Route
            path="app/recordings/*"
            element={
              <React.Suspense fallback={<></>}>
                <CallRecordsRoute />
              </React.Suspense>
            }
          />
          <Route
            path="app/timeZoneRestriction"
            element={
              <React.Suspense fallback={<></>}>
                <TimeZoneRestriction />
              </React.Suspense>
            }
          />
          <Route
            path="app/liveBoards"
            element={
              <React.Suspense fallback={<></>}>
                <QueueMonitor />
              </React.Suspense>
            }
          />
          <Route
            path="app/reports/*"
            element={
              <React.Suspense fallback={<></>}>
                <CallReportsRoute />
              </React.Suspense>
            }
          />
          <Route
            path="app/provision/*"
            element={
              <React.Suspense fallback={<></>}>
                <Provision />
              </React.Suspense>
            }
          />
          <Route
            path="app/dids/*"
            element={
              <React.Suspense fallback={<></>}>
                <Dids />
              </React.Suspense>
            }
          />
          <Route
            path="app/dcid/*"
            element={
              <React.Suspense fallback={<></>}>
                <Dcid_Management />
              </React.Suspense>
            }
          />
          <Route
            path="app/routing"
            element={
              <React.Suspense fallback={<></>}>
                <InitialRouter />
              </React.Suspense>
            }
          />
          <Route
            path="app/loader"
            element={
              <React.Suspense fallback={<></>}>
                <div className="h-[calc(100vh-120px)] w-full bg-gray-100 flex items-center justify-center">
                  <Loader />
                </div>
              </React.Suspense>
            }
          />
          <Route
            path="app/"
            element={
              <React.Suspense fallback={<></>}>
                <InitialRouter />
              </React.Suspense>
            }
          />
          <Route
            path="app/*"
            element={
              <React.Suspense fallback={<></>}>
                <div className="h-[calc(100vh-120px)] w-[calc(100vw-120px)]  flex items-center justify-center">
                  <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
                    <div className="max-w-md">
                      <div className="text-5xl font-dark font-bold text-center">
                        404
                      </div>
                      <p className="text-2xl md:text-3xl font-light leading-normal">
                        Sorry we couldn't find this page.!{" "}
                      </p>
                      {/* <p className="mb-8">
                        But dont worry, you can find plenty of other things on
                        our homepage.
                      </p>

                      <button className="px-4 inline py-2 text-sm font-medium leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none focus:shadow-outline-blue bg-blue-600 active:bg-blue-600 hover:bg-blue-700">
                        back to homepage
                      </button> */}
                    </div>
                    <div className="max-w-lg"></div>
                  </div>
                </div>
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};
export default RouterConfig;
