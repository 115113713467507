import React, { Children, useEffect, useState } from "react";
import FadeIn from "react-fade-in";
import {
  CrossIcon,
  EditPencilIcon,
  EditPencilIcon2,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import Loader from "../loader";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaginationButtons from "../../atom/pagination";
import { Backdrop, CircularProgress } from "@mui/material";
import Notification from "../../atom/Notification";
import Lottiefy from "../../atom/lottie/lottie";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { setBreadcrumbs } from "../../toolkit/reducers/breadCrumbsSlice";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import { add_New_DCID_Plan, get_NewPlan_Data } from "../../services/api";

function Add_Dcid_Plan() {
  const [manageState, setManageState] = useState<string>("");
  // const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(25);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "state",
      title: "State",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: true },
    },
    {
      key: "rate_center",
      title: "Rate Center",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "npa",
      title: "NPA",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "",
      type: "checkbox",
      classname: "float-right ",
      selectAll: true,
    },
  ]);

  const [tableData, setTableData] = useState<any>([]);

  const [searchtext, setSearchText] = useState<any>("");
  const [sort, setSort] = useState<any>({
    key: "state",
    order: "ASC",
  });
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(true);

  const path = useLocation();

  const onHandleFetch: any = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setLoader(true);
    get_NewPlan_Data({
      limit: options?.limit || perPage,
      pageId: options?.pageNumber || pageNumber,
      sort_column: options?.sort_column || sort.key,
      sort_direction: options?.sort_direction || sort.order,
      searchtext:
        options?.searchtext === undefined
          ? searchtext
          : options?.searchtext === ""
          ? ""
          : options?.searchtext,
    }).then((result: any) => {
      console.log("result", result.data);
      const plans = result?.data?.items;
      if (!result.success || plans?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
      }
      setTableData(plans);
      // setPlanData(result?.data);
      setLoader(false);
      setPageCount(result?.data.total);
    });
  };

  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_direction: orderBy });
  };

  const selectAllCheckbox = (action: any, data: any) => {
    console.log("checboxdata", data);
    const clone = [...tableData];
    const ttile = [...tableTitles];
    if (data?.selectAll === true) {
      console.log("rchdddddd");
      const result: any = ttile.find((node: any) => {
        console.log("keyy.node", node.key, "data.key", data.key);
        return node.key === data.key;
      });
      console.log("titlee", result);
      console.log("result", result);
      result.checked = !result?.checked || false;
      clone.map((node: any) => {
        node.checked = result?.checked;
      });
    } else {
      const result = clone.find((node: any) => {
        return node.uuid === data.uuid;
      });
      console.log("resultt1", result);

      result.checked = !result?.checked || false;
    }
    const result = clone.find((node: any) => {
      return node?.checked ? node?.checked : false;
    });
    console.log("ttilettile", ttile, "result", result);

    setTableTitles(ttile);
    setTableData(clone);
  };

  const get_DCID_TableData = (pgNum: number) => {
    setPageNumber(pgNum);
    onHandleFetch({ pageNumber: pgNum });
  };

  // this function handles every button clicks for navigation :)
  const onhandleStateChange = (state: any, data: any) => {
    console.log("statestate", state);
    if (state === "Rate Center") {
      state = "ratecenter";
    }
    navigate(`${state}`, { replace: false });
  };

  const actionHandler = (action: string, data: any, index: number) => {
    console.log("action", action, "data", data);
    const actionsMap: any = {
      "Rate Center": onhandleStateChange,
      selectall: selectAllCheckbox,
      change: selectAllCheckbox,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data);
    } else {
      console.log("actionHandler Error");
    }
  };

  // let data: any = { name: "", dids_per_npa: "" };
  const [data, setData] = useState<any>({ name: "", dids_per_npa: 0 });
  const handle_Textbox_Event = (e: any, item: any) => {
    console.log("eeeeee", e.target.value, "item", item);
    // if (item === "planName") {
    //   data.name = e.target.value;
    // } else if (item === "dids") {
    //   data.dids_per_npa = e.target.value;
    // }
    const value = e.target.value;

    setData((prevData: any) => ({
      ...prevData,
      [item === "planName" ? "name" : "dids_per_npa"]:
        item === "dids" ? parseInt(value) : value, // Convert dids_per_npa to an integer
    }));
  };

  const onHandleSubmit = () => {
    setLoader(true);
    const tableDataCopy = [...tableData];
    const npaIds = tableDataCopy
      .filter((node: any) => {
        return node?.checked;
      })
      .map((node: any) => {
        return node?.uuid;
      });

    let payload: any = {
      npa_ids: npaIds,
      dids_per_npa: data.dids_per_npa,
      name: data.name,
    };

    add_New_DCID_Plan(payload).then((result: any) => {
      console.log("pathpath", path);
      setLoader(true);
      console.log("result", result);
      if (!result.success) {
        setLoader(false);
        Notification(
          "Failed",
          `${
            result?.message
              ? result?.message
              : "Oops! Something went wrong. Please try again later"
          }`,
          "danger"
        );
      } else {
        Notification("Success", "Plan added successfully", "success");
        setLoader(false);
        // navigate(-1);
        navigate(`/app/dcid`, { replace: false });
      }
    });
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, pageNumber: 1 });
  };

  const handleChange = (pgNum: number) => {
    get_DCID_TableData(pgNum);
    setPageNumber(pgNum);
  };

  const onHandleCancel = () => {
    const clone = [...tableData];
    const title = [...tableTitles];
    const result = clone.map((node: any) => {
      return (node.checked = false);
    });
    title[3].checked = false;
    setTableData(clone);
    setTableTitles(title);
  };

  useEffect(() => {
    get_DCID_TableData(1);
  }, []);
  return (
    <div>
      <div className="h-full py-3 flex relative  rounded-md">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div
          className={`h-[calc(100vh-125px)] w-full  overflow-x-auto overflow-y-hidden  rounded-md bg-[#fff]`}
        >
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={"New DCID Plan"}
                button={true}
                manageState={manageState}
                setManageState={setManageState}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.post"}
                buttonText={"Rate Center"}
                search={false}
                searchtext={searchtext}
                setSearchText={setSearchText}
                handleKeyPress={handleKeyPress}
                CSV={false}
                setHandleKeyPress={setHandleKeyPress}
                actionHandler={actionHandler}
              />
            </div>

            <div className="p-2 ">
              <div className="flex p-2 gap-x-[68px] ">
                <label className="text-[#7c7c7c] font-semibold text-[16px] items-center flex">
                  Plan Name
                </label>
                <input
                  type="text"
                  className="border border-[#696969] w rounded p-2 w-[340px] focus:outline-none "
                  onChange={(e) => handle_Textbox_Event(e, "planName")}
                />
              </div>

              <div className="flex p-2 gap-x-[48px] ">
                <label className="text-[#7c7c7c] font-semibold text-[16px] items-center flex">
                  DIDs per NPA
                </label>
                <input
                  type="number"
                  className="border border-[#696969] w rounded p-2 w-[340px] focus:outline-none "
                  onChange={(e) => handle_Textbox_Event(e, "dids")}
                />
              </div>
              <div className="flex"></div>
            </div>
            <div className="h-[calc(100vh-500px)]">
              <Table
                tableTitles={tableTitles}
                tableData={tableData}
                setTableData={setTableData}
                actionHandler={actionHandler}
                setTableTitles={setTableTitles}
                button={true}
                buttonIndex={4}
                buttonText1={"manage"}
                width={"w-[calc(100vw-122px)] overflow-auto"}
                scrollHeight={"h-[calc(100vh-480px)] overflow-y-auto"}
                manageState={manageState}
                setManageState={setManageState}
                onHandleSort={onHandleSort}
                onhandleStateChange={onhandleStateChange}
                permission={"provision.users.put"}
                actions={true}
                resultNotFound={resultNotFound}
              />
            </div>

            <div className=" h-full py-4 px-3 flex  mt-2 float-right">
              <ThemedButton
                children={"Cancel"}
                icon="custom"
                theme="secondary"
                // valiData={callReport}
                // change={setCallReport}
                click={onHandleCancel}
              />
              <ThemedButton
                children={"Submit"}
                icon="custom"
                theme="primary"
                click={() => {
                  onHandleSubmit();
                }}
                // valiData={tableData}
                // change={setTableData}
                // click={onHandleUpdate}
                // loading={buttonLoader}
              />
            </div>

            {!resultNotFound && tableData.length > 0 && (
              <PaginationButtons
                pageNumber={pageNumber}
                handleChange={handleChange}
                pageCount={pageCount}
                perPage={perPage}
                handlePerPage={handlePerPage}
              />
            )}
          </FadeIn>
        </div>
      </div>
    </div>
  );
}

export default Add_Dcid_Plan;
